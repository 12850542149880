import { useState } from 'react';
import { MapContainer, TileLayer } from "react-leaflet";
import { Available } from "./forecast";
import TimeControl from "./components/TimeControl";
import ServerControl from './components/ServerControl';
import ForecastLayerControl from './components/ForecastLayerControl';
import { allServers } from './server';

export default function Map() {

    const [serverURL, setServerURL] = useState<string>(allServers[0].url)
    const [currentForecast, setCurrentForecast] = useState<Available | null>(null);
    const [timeStep, setTimeStep] = useState<number>(0);

    return (
        <>
            <MapContainer center={[65, 10]} zoom={5} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ForecastLayerControl
                    serverURL={serverURL}
                    timeStep={timeStep}
                    onLayerSelected={setCurrentForecast}
                />
                <ServerControl serverURL={serverURL} onChange={setServerURL} />
            </MapContainer>
            <TimeControl
                timeSteps={currentForecast?.times.map(step => step.time) || []}
                onChange={setTimeStep}
            />
        </>
    );
}

