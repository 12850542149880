import { useEffect, useState } from 'react';
import { LayersControl, TileLayer } from "react-leaflet";
import { Available, getAllLayers } from "../forecast";


export interface ForecastLayerControlProps {
    serverURL: string;
    timeStep: number;
    onLayerSelected: (a: Available | null) => void;
}

export default function ForecastLayerControl(props: ForecastLayerControlProps) {
    const [allAvailable, setAllAvailable] = useState<Available[]>([]);

    useEffect(() => {
        getAllLayers(props.serverURL)
            .then(a => setAllAvailable(a))
    }, [props.serverURL])

    return <LayersControl position="topright">
        {allAvailable.map(a => {
            var url = "";
            if (props.timeStep < a.times.length) {
                url = a.times[props.timeStep].tiles.webp;
            }
            return <LayersControl.BaseLayer name={a.name} key={a.name}>
                <TileLayer
                    attribution='Forecast data by <a href="https://met.no">Met Norway</a>'
                    url={url}
                    opacity={0.5}
                    eventHandlers={{
                        add: () => props.onLayerSelected(a)
                    }} />
            </LayersControl.BaseLayer>;
        }
        )}
    </LayersControl>
}