import { z } from "zod";

const available = z.object({
    name: z.string(),
    bounds: z.array(z.number()),
    minZoom: z.number().int(),
    maxZoom: z.number().int(),
    times: z.array(z.object({
        time: z.string().datetime(),
        tiles: z.object({
            webp: z.string()
        })
    }))
});

const layerDescriptionList = z.array(
    z.object({
        name: z.string(),
        description: z.string(),
        url: z.string(),
    })
)

// extract the inferred type
export type Available = z.infer<typeof available>;

export async function getLayer(metadataURL: string): Promise<Available> {
    const response = await fetch(metadataURL);
    const ret = available.parse(await response.json());
    return ret;
}

export async function getAllLayers(serverURL: string): Promise<Available[]> {
    const layersListURL = serverURL + "/api/available.json";

    const response = await fetch(layersListURL);
    const layers = layerDescriptionList.parse(await response.json())

    const jobs: Promise<Available>[] = []
    for (const layer of layers)
        jobs.push(getLayer(layer.url))

    const ret: Available[] = []
    for (const job of jobs)
        ret.push(await job)

    return ret;
}
