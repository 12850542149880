export interface ServerSpec {
    id: string
    displayName: string
    url: string
}

export const allServers: ServerSpec[] = [
    {
        id: "prod",
        displayName: "Prod",
        url: "https://prod.yr-maps.met.no"
    },
    {
        id: "staging",
        displayName: "Staging",
        url: "https://staging.yr-maps.met.no"
    }
]
