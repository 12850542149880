import { Box, Slider, Stack, SxProps, Theme, Typography } from "@mui/material"
import moment from "moment";
import { useState } from "react";


export interface TimeControlProps {
    timeSteps: string[]
    onChange: (index: number) => void;
}

export default function TimeControl(props: TimeControlProps) {
    const [timeStep, setTimeStep] = useState<number>(0)

    const formattedTime = (idx: number) => {
        if (props.timeSteps.length === 0)
            return ""
        return moment(props.timeSteps[timeStep]).format('YYYY-MM-DD HH:mm')
    }

    return (
        <Box sx={sx}>
            <Stack spacing={2} direction="column" sx={{ alignItems: 'center', width: '100%' }}>
                <Typography variant="body1" gutterBottom>
                    Time {formattedTime(timeStep)}
                </Typography>
                <Slider
                    defaultValue={0}
                    aria-label="Time step"
                    getAriaValueText={(value: number, index: number) => props.timeSteps[index]}
                    valueLabelDisplay="auto"
                    max={props.timeSteps.length - 1}
                    marks
                    onChange={
                        (_event: React.SyntheticEvent | Event, value: number | number[]) => {
                            const idx = value as number
                            setTimeStep(idx)
                            props.onChange(idx)
                        }
                    }
                    sx={{ width: '100%' }}
                />
            </Stack>
        </Box>
    )
}

const sx: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    padding: 2,
    backgroundColor: '#fff',
    borderRadius: 2,
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    bottom: '16px',
    left: '30%',
    pointerEvents: 'auto',
    zIndex: 1000,
}