import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { allServers } from "../server";

interface ServerControlProps {
    serverURL: string
    onChange: (serverURL: string) => void;

}

export default function ServerControl(props: ServerControlProps) {
    const handleChange = (event: SelectChangeEvent) => {
        const id = event.target.value as string
        const url = allServers.find((s) => s.id === id)?.url || ""
        console.log("new url:", url)
        props.onChange(url)
    };

    const selected = allServers.find(v => v.url === props.serverURL)?.id || "no server"

    return (
        <div className="leaflet-bottom leaflet-left">
            <div className="leaflet-control leaflet-bar">
                <Box sx={{
                    backgroundColor: '#fff',
                }}>
                    <Select
                        value={selected}
                        onChange={handleChange}
                    >
                        {
                            allServers.map(spec =>
                                <MenuItem key={spec.id} value={spec.id}>{spec.displayName}</MenuItem>
                            )
                        }
                    </Select>
                </Box>
            </div>
        </div>
    )
}
